<template>
    <div>
        <el-drawer
            :title="sessionName +' 排号设置'"
            :visible.sync="drawerShow"
            :wrapperClosable="false"
            :destroy-on-close="true"
            size="auto"
            @close="close"
        >
            <div class="drawer-content">
				<el-tabs v-model="tabValue" @tab-click="handleClick">
					<el-tab-pane name="1">
						<span slot="label">领取排号</span>
						<template  v-if="tabValue == '1'">
							<div>
								<div class="font16 MT20">请选择排队类型</div>					
								<el-select v-model="type" size="medium" style="width: 80%; margin-top: 10px;">					    
									<div class="MT20"><el-option :value="1" label="场次通排（所有票品拉通排号）"></el-option></div>
									<div class="MT20"><el-option :value="2" label="分组排号（为不同票品创建分组单独排号）"></el-option></div>
								</el-select>
							</div>
							<div class="MT50" v-if="type == 2">
								<div class="font16">请设置分组票品 <el-button type="text" size="mini" style="margin-left: 20px;" @click="addGroup()" v-if="ParamsNum <= 3 ">新增分组</el-button></div>	
								<div class="grouplineList">
									<div class="fix" v-for="num in ParamsNum" :key="num">
										<el-tag  type="success" size="medium" v-if="num == 1">A组</el-tag>
										<el-tag  type="success" size="medium" v-if="num == 2">B组</el-tag>
										<el-tag  type="success" size="medium" v-if="num == 3">C组</el-tag>
										<el-tag  type="success" size="medium" v-if="num == 4">D组</el-tag>
										<el-select  multiple placeholder="请选择票品" class="W70" v-model="temp.params[num].key" @change="grouplineChange">
											<el-option
											  v-for="(item, index) in ticketPrice" :key="item.key"					      
											  :label="item.value"
											  :value="item.key"
											  :disabled="item.disabled">
											</el-option>
										  </el-select>
										  <i class="el-icon-remove-outline ML20" @click="del(num)" ></i>
									</div>						 
								</div>
							</div>
							<div class="MT50">
								<div class="font16">出号方式</div>
								<p>根据用户在秀动App点击领取排号的时间从近到远从NO.1开始依次出号</p>
							</div>
							<div class="MT50">                    
								<div class="font16">领取排号的开始时间</div>				
								<el-radio-group v-model="buildTime">
									<div class="MT20"><el-radio :label="0">演出开始前1天前允许领取排号</el-radio></div>
									<div class="MT20"><el-radio :label="1">演出开始当天允许取排号</el-radio></div>
									<div class="MT20"><el-radio :label="2">演出开始前6个小时允许领取排号</el-radio></div>
									<div class="MT20"><el-radio :label="3"> 创建后立即允许领取排号</el-radio></div>
								</el-radio-group>
							</div>							
						</template>
					</el-tab-pane>
					 <el-tab-pane name="2">
						<span slot="label">系统排号</span>
						<template  v-if="tabValue == '2'">
							<div>
								<div class="font16 MB20">请选择排队类型</div>					
								<el-select v-model="type" size="medium" style="width: 80%;">					    
									<div class="MT20"><el-option :value="1" label="场次通排（所有票品拉通排号）"></el-option></div>
									<div class="MT20"><el-option :value="2" label="分组排号（为不同票品创建分组单独排号）"></el-option></div>
								</el-select>
							</div>
							<div class="MT50" v-if="type == 2">
								<div class="font16">请设置分组票品 <el-button type="text" size="mini" style="margin-left: 20px;" @click="addGroup()" v-if="ParamsNum <= 3 ">新增分组</el-button></div>	
								<div class="grouplineList">
									<div class="fix" v-for="num in ParamsNum" :key="num">
										<el-tag  type="success" size="medium" v-if="num == 1">A组</el-tag>
										<el-tag  type="success" size="medium" v-if="num == 2">B组</el-tag>
										<el-tag  type="success" size="medium" v-if="num == 3">C组</el-tag>
										<el-tag  type="success" size="medium" v-if="num == 4">D组</el-tag>
										<el-select  multiple placeholder="请选择票品" class="W70" v-model="temp.params[num].key" @change="grouplineChange">
											<el-option
											  v-for="(item, index) in ticketPrice" :key="item.key"					      
											  :label="item.value"
											  :value="item.key"
											  :disabled="item.disabled">
											</el-option>
										  </el-select>
										  <i class="el-icon-remove-outline ML20" @click="del(num)" ></i>
									</div>						 
								</div>
							</div>
							<div class="MT50">
								<div class="font16">请选择出号方式</div>				
								<el-radio-group v-model="numberType">
									<div class="MT20">
										<el-radio :label="0">按系统出票时间从前往后排
											
										</el-radio>
									</div>
									<div class="MT20">
										<el-radio :label="1" :disabled="data.store != 0">按系统出票时间从后往前排 
											<span v-if="data.store == 0">（排队序号生成后新的订单会直接追加序号）</span>
											<span v-else>（库存售罄时可选择，排队序号生成后新的订单会直接追加序号）</span>
										</el-radio>
									</div>
									<div class="MT20">
										<el-radio :label="2" :disabled="data.store != 0">随机排序，跟系统出票时间无关
										<span v-if="data.store == 0">（排队序号生成后新的订单会直接追加序号）</span>
										<span v-else>（库存售罄时可选择，排队序号生成后新的订单会直接追加序号）</span>
										</el-radio>
									</div>
								</el-radio-group>
								<small class="color-danger">
									<p class="MT10">出票时间：支付平台确认用户支付成功后，通知秀动系统正式出票的时间。</p>
									<p>注意：出票时间不等于支付平台（例如支付宝/微信）显示的支付时间。</p>
								</small>
							</div>                			
							<div class="MT50">                    
								<div class="font16">请选择排队序号展示时间</div>				
								<el-radio-group v-model="buildTime">
									<div class="MT20"><el-radio :label="0">演出开始前1天展示</el-radio></div>
									<div class="MT20"><el-radio :label="1">演出开始当天0点展示</el-radio></div>
									<div class="MT20"><el-radio :label="2">演出开始前6个小时展示</el-radio></div>
									<div class="MT20"><el-radio :label="3">创建后立即生成展示</el-radio></div>
								</el-radio-group>
							</div>
							<div class="MT50">
								<div class="font16">赠票参与排号 <small class="color-danger">(开启后赠票会以赠票时间参与排号)</small></div>				
								<el-radio-group v-model="buildPresent" @change="changeBuildpresent">
									<div class="MT20"><el-radio :label="1">开启</el-radio></div>
									<div class="MT20"><el-radio :label="0">关闭</el-radio></div>
								</el-radio-group>
							</div>	
						</template>
					 </el-tab-pane>
					 <el-tab-pane name="3">
						<span slot="label">自定义上传</span>
						<template  v-if="tabValue == '3'">
							<div class="temp-head clearfix">
									<div class="font16 MB20">1、请先下载售票排队号导入表，在表格中填写每张售票对应的排队号</div>
									<el-button type="primary" @click="download(1)" size="small" :loading="downLoading">下载排队号导入表</el-button>									
							</div>                            
							<div class="temp-head clearfix MT50">
								<div class="font16 MB20">2、填写好售票排队号导入表后，在下方上传。上传成功后会在用户票夹中显示排队号。</div>
									<div>
										<el-upload
											action
											:http-request="uploadExcel"
											:auto-upload="false"
											ref="upload"
											accept=".xls, .xlsx"
											:show-file-list="true"
											:before-upload="beforeUpload"
											:file-list="fileList"
											:on-change="onChange"
											:on-remove="onRemove"
											:disabled="uploadLoading"
											style="width:100%">
											<el-button size="small" style="width:100%" type="primary" :loading="uploadLoading">{{uploadLoading ? '上传中' : '上传排队号导入表'}}</el-button>
										</el-upload>
									</div>
									<div class="download color-theme MT20" v-if="siteFileUrl && data.siteFileUrl" style="cursor: pointer;"  @click="downloadExcel(data.siteFileUrl)" >{{sessionName}}排队号导入表.xlsx</div>
									<div v-if="MsgText"  class="MT20" >
										<el-alert show-icon type="error" v-if="MsgText" :closable="false">
											<div slot="title">上传失败：{{MsgText}}</div>
										</el-alert>
									</div>									
							</div>				
							<div class="MT50">                    
								<div class="font16">请选择排队号号展示时间</div>				
								<el-radio-group v-model="buildTime">
									<div class="MT20"><el-radio :label="0">演出开始前1天展示</el-radio></div>
									<div class="MT20"><el-radio :label="1">演出开始当天0点展示</el-radio></div>
									<div class="MT20"><el-radio :label="2">演出开始前6个小时展示</el-radio></div>
									<div class="MT20"><el-radio :label="3">创建后立即生成展示</el-radio></div>
								</el-radio-group>
							</div>							
						</template>
					 </el-tab-pane>					 
				</el-tabs>
							              
            </div>
            <div class="drawer-footer">
                <el-button @click="close">取消</el-button>
                <el-button type="primary" @click="onSubmit" :loading="btnLoading" :disabled="canSubmit"
                    >确认创建</el-button>
            </div>
        </el-drawer>
    </div>
</template>

<script>
import {downloadFileTmp,uploadFileTmp} from '@/assets/js/commonApi.js';
import { array } from 'js-md5';
export default {   
    components:{},
    data(){
        return{
            drawerShow:false,
            btnLoading:false,
            downLoading:false,
            uploadLoading:false,
            canSubmit:false,
			fileList:[],
			type:1,
			numberType:0,
			buildTime:3,
			sessionName:'',
			data:{},
			groupLine:[],
			ticketPrice:[],
			
			grouplineName:[],
			num:1,
			
			temp: {
				params: [{key:''}]
			},
			ParamsNum: 0,
			//1.18
			tabValue:'1',
			buildPresent:0,
			siteFileUrl:false,
			filePath:'',
			fileFullPath:'',
			MsgText:'',
			showType:''
			
        }
    },
    methods:{
        
		show(data) {			
			this.data = data
			console.log(this.data)
		    this.id = data.sessionId;
		    this.sessionName = data.sessionName
		    this.drawerShow = true;
			this.ticketPrice = data.ticketPrice	
					
			if( this.data.siteFileUrl ){
				this.siteFileUrl = true
			}
			if( this.data.buildTime ){
				this.buildTime = this.data.buildTime
			}else{
				this.buildTime = 0
			}
			
			
			if( this.data.buildPresent ){
				this.buildPresent = this.data.buildPresent
			}else{
				this.buildPresent = 0
			}
			var arr3= this.data.ticketPrice.concat(this.data.presentTicketPrice)
			this.ticketPrice = this.buildPresent == 1 ? arr3 : this.data.ticketPrice
			
			
		},
        close(){            
            this.drawerShow = false;
			this.canSubmit = false
			this.ParamsNum = 0
			this.temp.params.push({key:''})
			this.data.siteFileUrl = ''
        },
		handleClick(tab, event) {
			this.type = 1
		},
		addGroup(){
			this.temp.params.push({key:''})
			this.ParamsNum++
			if( this.ParamsNum <= 4 ){
				return
			}			
		},
		del(num) {
			this.ParamsNum--
			this.temp.params.splice(num,1)
			this.grouplineChange()
		},
        grouplineChange(e){            
            var _arr = []
            this.ticketPrice.map( (item,index) => {
                item.disabled = false
                this.temp.params.map( (pitem,pindex) => {
                    if(pitem.key.length > 0){
                        pitem.key.map( (kitem,kindex) => {
                            if( item.key == kitem){
                                item.disabled = true				 
                            }
                        })
                    }
                })
                _arr.push(item.value)
		     
		  })
		 this.grouplineName = _arr
	   },
		changeBuildpresent(){
			if( this.buildPresent == 1 ){
				var arr3= this.data.ticketPrice.concat(this.data.presentTicketPrice)				
				this.ticketPrice = arr3
			}else{
				this.ticketPrice = this.data.ticketPrice
			}
		},
        onSubmit(){
			if( this.tabValue == '1' || this.tabValue == '2' || this.siteFileUrl ){
				var _groupLine = {}
				this.temp.params.map( (pitem,pindex) => {
				    if(pitem.key.length > 0){
				        if (pindex == 1){
				            _groupLine.A = pitem.key
				        }else if (pindex == 2){
				            _groupLine.B = pitem.key
				        }else if (pindex == 3){
				            _groupLine.C = pitem.key
				        }else if (pindex == 4){
				            _groupLine.D = pitem.key
				        }					
				    }
					
				})
				
				if( this.type == 2 || this.type == 5 ) {
					if( JSON.stringify(_groupLine) == '{}' ){
						return this.$message.error( '请设置分组票品！');
					}
					if( Object.values(_groupLine).length != this.ParamsNum ){
						return this.$message.error( '请设置分组票品！');
					}				
				}
				
				this.type = this.tabValue ==='1' ? 5 : this.tabValue ==='3' ? 4 : this.type
				console.log(this.type)
				//return
				
				this.$request({
				    url:'/activity/queue/number/setting',
				    method: 'post',
				    data:{
						buildTime:this.buildTime,
						numberType:this.numberType,
						sessionId: this.id,
						type:this.type,
						groupLine:_groupLine,
						buildPresent:this.buildPresent,
						filePath:this.filePath
					}
				})
				    .then(res => {
				        const{state,msg,result} = res.data;
				        if (state == 1) {
				            this.$message.success('操作成功');
							this.$emit('onUpdate');
							this.close();
				            //window.location.reload()
				            
				        }else{
				            this.$message.error(msg || '操作失败');
				            this.canSubmit = false;
				        }
				    })
			}else{
				this.$refs.upload.submit();
			}  
        },
		//自定义相关
		async downloadExcel(siteFileUrl){
		    const _url = siteFileUrl;		    
			await downloadFileTmp(_url, this.name + '排队号导入表.' + this.$utils.getFileType(_url));		   
		},		
		//下载模板
		download(type){
			let filePath;
			if( type == 1 ){
				this.downLoading = true;
			}			
			this.$request({
				url: '/activity/queue/number/template',
				method: 'POST',
				params: {sessionId:this.id } ,
			}).then(res => {
				const { state, msg, result } = res.data;
				if( state == 1 && result ){
					filePath = result.url;					
					downloadFileTmp(filePath,this.sessionName + `排队号导入表.`+this.$utils.getFileType(filePath));
					if( type == 1 ){
						this.downLoading = false;
					}					
				}else{
					if( type == 1 ){
						this.downLoading = false;
					}					
					return this.$message.error(msg || '下载失败');
				}
			}).catch(() => {
				this.$message.error('请求错误');
			}).finally(() => {
				
				if( type == 1 ){
					this.downLoading = false;
				}
			});
		},
		async uploadExcel(req){
			
		    this.btnLoading = true;
		    this.uploadLoading = true;
		    const formData = new FormData();
		    formData.append('file', req.file);
		
		    let filePath;
			let fileFullPath;
		    const res = await uploadFileTmp(formData).catch(err => {
		        this.$message({message: err.message, type: 'error'});
		    }).finally(() => {
		        this.uploadLoading = false;
		    });
		    const{state,msg,result} = res.data;
		    if (state == 1 && result) {
				
		        filePath = result.url;
				fileFullPath = result.urlFull
				
				this.filePath = result.url
				this.fileFullPath = result.urlFull
		    }else{
		        this.$message.error(msg || '上传失败');
		    }
			
			
			this.type = this.tabValue ==='1' ? 5 : this.tabValue ==='3' ? 4 : this.type
			console.log(this.type)
			//return
			
		    this.$request({
		        url:'/activity/queue/number/setting',
		        method: 'post',
		        data:{
		    		buildTime:this.buildTime,
		    		numberType:this.numberType,
		    		sessionId: this.id,
		    		type:this.type,
		    		groupLine:{},
		    		buildPresent:this.buildPresent,
		    		filePath:filePath,
		    	}
		    })
		        .then(res => {
		            const{state,msg,result} = res.data;
		            if (state == 1) {
		                this.$message.success('操作成功');
		                //window.location.reload()
						this.$emit('onUpdate');
						this.close();
		                
		            }else{
		                this.$message.error(msg || '操作失败');
		                this.canSubmit = false;
		            }
		        })
		       
		},
		beforeUpload(file){
		    const type = this.$utils.getFileType(file.name)
		    const isType = type === 'xls' || type === 'xlsx';
		    if (!isType) {
		        this.$message.error('仅支持xls、xlsx文件格式,请重新选择上传文件!');
		    }
		    return isType;
		},
		onSuccess(){
		    this.$emit('onUpdate');
		    this.close();
		},
		onChange(file){
		    if( file ){
				this.siteFileUrl = false				
				this.MsgText = ''
		        this.fileList = [{...file}]
		        this.canSubmit = false;
		    }else{
		        this.canSubmit = true;
		    }
		},
		onRemove(){
		    this.canSubmit = true;
		}
		
       
    }
}
</script>

<style lang="scss" scoped>
.temp-head{
    line-height: 28px;
}
.temp-table{
    margin-top: 15px;
}
.temp-tips{
    border-bottom: 1px #EBEEF5 solid;
    padding-bottom: 15px;
    .el-icon-info{
        font-size: 16px;
    }
    .color-regular{
        margin-top: 8px;
        font-size: 13px;
        line-height: 20px;
    }
}
.font16{
	font-size:16px;
	color:#000
}
.MT50{
	margin-top: 50px;
}
.MB20{
	margin-bottom: 20px;
}
.W70{
	width: 70%;
	margin-left: 20px;
}
.grouplineList{
    margin-top: 20px;
	.fix{
		display: flex;
		justify-content: flex-start;
		align-items: center;
		margin-top: 20px;
		.groupBox{
			margin-left: 30px;
		}
	}
}
</style>