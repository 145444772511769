<template>
    <div>
        <div class="page-top-box">
            <div class="page-title-box">
                <div class="go-back">
                    <el-button type="text" icon="el-icon-back" @click="goBack">返回</el-button>|
                </div>
                {{ $route.meta.title }}
            </div>
        </div>
        <!-- <activity-tab name="2"></activity-tab> -->
        <div v-loading="dataLoading">
            <div class="page-group-box">
                <div class="page-group-title" v-if="data">
					{{data.activityName}} 排队序号管理
					<small><span class="color-danger ML20">距离演出开演时间在30分钟及以上的场次才可以设置排队序号</span></small>
				</div>
                <el-row class="info-box">
                    <el-col :xl="24">
                        <el-row :gutter="40">                            
							<el-col :span="24">
							    <small class="color-info fr"  @click="agreementVisible = true">创建排队序号时默认同意<span class="color-theme">《秀动排队序号功能使用协议》</span></small>
							</el-col>
                        </el-row>
                    </el-col>
                </el-row>               
            </div>
            
			<div v-if="data && data.dataList.length > 0" class="page-group-box MT10">
			    <div class="page-group-title">排号管理列表</div>
			    <el-table
			        :data="data.dataList"
			        style="width: 100%"
			        size="small"
			        header-row-class-name="page-list-table"
			        class="MT20"
			    >
			        <el-table-column prop="sessionName" label="场次"></el-table-column>
			        <el-table-column prop="sellNum" label="售票数"></el-table-column>
					<el-table-column prop="store" label="库存"></el-table-column>
					<el-table-column prop="type"  label="排号类型">
						<template slot-scope="scope">						   
						    <span v-if="scope.row.type == 1">通排</span>
						    <span v-else-if="scope.row.type == 2">分组</span>
							<span v-else-if="scope.row.type == 4">自定义排号</span>
							<span v-else-if="scope.row.type == 5">领取排号</span>
							<span v-else>未设置</span>
						</template>
					</el-table-column>
					<el-table-column prop="numberType" label="出号方式">
						<template slot-scope="scope">
							<template v-if="scope.row.type == 5">
								<span>根据用户领取时间先后出号</span>
							</template>
							<template v-else-if="scope.row.type == 4">								
								<span>自定义上传排号</span>
							</template>
							<template v-else>
								<span v-if="scope.row.numberType == 0">系统出票时间从前往后排</span>
								<span v-else-if="scope.row.numberType == 1">系统出票时间从后往前排</span>
								<span v-else-if="scope.row.numberType == 2">自定义上传排号</span>
								<span v-else>未设置</span>
							</template>						    
						</template>
					</el-table-column>
					<el-table-column prop="startTime" label="开演时间"></el-table-column>
					<el-table-column  label="操作">
						<template slot-scope="scope">
							<el-button
							    type="text"
							    size="small"
								@click="$refs.BatchNumData.show(scope.row)"
								v-if="scope.row.type == 0 "	
							    >
								创建排号
								</el-button
							>
							<el-button
							    type="text"
							    size="small"
								@click="$refs.BatchNumDetails.show(scope.row)"
								v-if="scope.row.type != 0 "		
							    >
								 查看详情
								</el-button
							>
							<el-button
							    type="text"
							    size="small"
								v-if="scope.row.type != 0 "		
								@click="download(scope.row.sessionName,scope.row.sessionId)"
							    >
								 导出序号
								</el-button
							>
						</template>
						
					</el-table-column>
			    </el-table>
			</div>
            <div class="page-group-box MT10">
                <el-button @click="goBack">返回</el-button>
            </div>
        </div>
        <BatchNumData ref="BatchNumData" :id="id"  @onUpdate="getData"></BatchNumData>
		<BatchNumDetails ref="BatchNumDetails" :id="id"  @onUpdate="getData"></BatchNumDetails>
		<el-dialog
		    title="排队序号功能使用协议"
		    :visible.sync="agreementVisible"
		    width="500px"
		    center>
		    <div class="dialog-content">
		      <div>您好，欢迎使用秀动提供的排队序号功能，在使用排队序号功能前请仔细阅读并理解《秀动排队序号功能使用协议》，以下简称协议。当您为场次创建协议时我们将视为您已阅读并同意协议。</div>
			  <div class="Atitle">第一条：功能概念</div>
			  <p>每场演出开始前30分钟及以上，可以在秀动经营平台设置对应的排队入场序号。排队入场序号在创建后，会根据选择的显示时间在用户的秀动app票夹中显示。排队入场序号用于帮助主办维持线下的入场秩序以及入场效率。具体设置方式请在创建排队序号中查看。</p>				       
			 <div class="Atitle">第二条：功能适用的演出</div>
			  <p>2.1 演出开始前30分钟及以上创建。</p>
			  <p>2.2 比较热门的重点演出，需要排队序号维持入场秩序和提升入场效率。</p>				       
			  <div class="Atitle">第三条：排队序号生成不可逆</div>
			  <p>演出场次的排队序号一旦生成，将不可撤回，请谨慎创建排队序号。</p>				       
			  <div class="Atitle">第四条：排队序号的解释权</div>
			  <p>创建的排队序号解释权归主办所有，秀动不对您在经营平台创建的排队序号负有解释权</p>				       
			   <div class="Atitle">第五：关于排队序号带来的用户纠纷和争议</div>
			   <p>线下由排队序号带来的用户纠纷和争议，将由设置排队序号的主办自己承担和处理。</p>
		    </div>
		    <span slot="footer" class="dialog-footer">		        
		        <el-button type="primary"  @click="agreementVisible = false">确 定</el-button>
		    </span>
		</el-dialog>
    </div>
</template>

<script>
import {downloadFileTmp} from '@/assets/js/commonApi';
import BatchNumData from './components/batchNumData';
import BatchNumDetails from './components/batchNumDetails';
export default {
    props: {
        id: {
            type: [Number, String],
            default: null,
        },
		name: {
		    type:String,
		    default:'',
		}
    },
    components:{BatchNumData,BatchNumDetails},
    data(){
        return{
            redirect: this.$route.query.redirect || '/manage/activity/list',
            dataLoading:false,
            data:null,
            downLoading: false,
			agreementVisible:false,
			sessionName:'',
			
        }
    },
    mounted(){
	   
       this.getData()
    },
	
    methods:{
        //返回
        goBack() {
            if(this.redirect){
                this.$router.push({
                    path: this.redirect,
                });
            }else{
                this.$router.go(-1);
            }
        },
        //获取数据
        getData(){
			
            this.dataLoading = true;
            this.$request({
                url: "/activity/queue/number/list",
                params: { activityId: parseInt(this.id) },
                method: "post",
            })
                .then((res) => {
                    const { state, msg, result } = res.data;
                    if (state == 1) {
                        this.data = result;
						console.log(this.data)
                    } else {
                        this.$message.error(msg || "获取信息失败,请重试");
                    }
                })
                .catch(() => {
                    this.$message.error("获取信息失败,请重试");
                })
                .finally(() => {
                    this.dataLoading = false;
                });
        },
		async download(name,sessionId){
		    let filePath;
		    this.downLoading = true;
		    const res = await this.$request({
		        url:'/activity/queue/number/export',
		        method: 'post',
		        params: { activityId: parseInt(this.id),sessionId:sessionId}
		    })
		        .catch(err => {
		            this.$message({message: err.message, type: 'error'});
		        })
		    const{state,msg,result} = res.data;
		    if(state == 1 && result){
		        filePath = result.url;
		    }else{
		        this.downLoading = false;
		        return this.$message.error(msg || '下载失败');
		    }   
		
		    await downloadFileTmp(filePath, name + '排队序号.'+this.$utils.getFileType(filePath));   
			
			
		    this.downLoading = false;
		},
       
    }
   
}
</script>

<style lang="scss" scoped>
.info-box{
    .el-col{
        .el-col{
            font-size: 16px;
            margin-top: 15px;
            label{
                display: inline-block;
                width: 50%;
            }
        }
    }
}
// .sessions-box{
//     margin-top: 30px;
//     .card-box{
//         margin-top: 10px;
//         border-radius: 4px;
//         border: 1px solid $--border-color-base;
//         background: rgba(235, 238, 245, 0.2);
//         padding: 5px 20px 20px 20px;
//     }
// }
.page-group-title{
    small{
        font-size: 13px;
    }
}
.Atitle{
	font-size:16px;
	color:#000;
	margin-top: 20px;
	margin-bottom: 8px;
}
</style>