<template>
    <div>
        <el-drawer
            :title="sessionName+' 排号详情'"
            :visible.sync="drawerShow"
            :wrapperClosable="false"
            :destroy-on-close="true"
            size="auto"
            @close="close"
        >
            <div class="drawer-content">
				<el-tabs v-model="tabValue">
					<el-tab-pane name="1" :disabled="data.type != 5">
						<span slot="label">领取排号</span>
						<template  v-if="tabValue == '1'">
							<div>
								<div class="font16 MB20">排队类型</div>					
								<el-select v-model="data.type" size="medium" style="width: 80%;" disabled>					    
									<div class="MT20"><el-option :value="1" label="场次通排（所有票品拉通排号）"></el-option></div>
									<div class="MT20"><el-option :value="2" label="分组排号（为不同票品创建分组单独排号）"></el-option></div>
									<div class="MT20"><el-option :value="5" label="场次通排（所有票品拉通排号）" v-if="!data.groupLine"></el-option></div>
									<div class="MT20"><el-option :value="5" label="分组排号（为不同票品创建分组单独排号）" v-if="data.groupLine"></el-option></div>				
								</el-select>
							</div>
							<div class="MT50" v-if="data.type == 5">
								<div class="font16">票品分组</div>					
							    <div class="grouplineList" v-if="data.groupLine">
									<div class="fix" v-for="(item,index) in data.groupLine" :key="index">
										<el-tag  type="success" size="medium">{{index}}组</el-tag>
										      <div v-for="(iitem, iindex) in item" class="ML20">
												<span v-for="(titem, tindex) in data.ticketPrice" v-if="titem.key == iitem">{{titem.value}}</span>
												<span v-for="(titem, tindex) in data.presentTicketPrice" v-if="titem.key == iitem">{{titem.value}}</span>
										    </div>
									</div>						 
								</div>								
							</div>
							<div class="MT50">
								<div class="font16">出号方式</div>
								<p>根据用户在秀动App点击领取排号的时间从近到远从NO.1开始依次出号</p>
							</div>
							<div class="MT50">                    
								<div class="font16">领取排号的开始时间</div>				
								<el-radio-group v-model="data.buildTime" disabled>
									<div class="MT20"><el-radio :label="0">演出开始前1天前允许领取排号</el-radio></div>
									<div class="MT20"><el-radio :label="1">演出开始当天允许取排号</el-radio></div>
									<div class="MT20"><el-radio :label="2">演出开始前6个小时允许领取排号</el-radio></div>
									<div class="MT20"><el-radio :label="3"> 创建后立即允许领取排号</el-radio></div>
								</el-radio-group>
							</div>							
						</template>
					</el-tab-pane>
					 <el-tab-pane name="2" :disabled="data.type != 1 && data.type != 2">
						<span slot="label">系统排号</span>
						<template  v-if="tabValue == '2'">
							<div>
								<div class="font16 MB20">排队类型</div>					
								<el-select v-model="data.type" size="medium" style="width: 80%;" disabled>					    
									<div class="MT20"><el-option :value="1" label="场次通排（所有票品拉通排号）"></el-option></div>
									<div class="MT20"><el-option :value="2" label="分组排号（为不同票品创建分组单独排号）"></el-option></div>
								</el-select>
							</div>
							<div class="MT50" v-if="data.type == 2">
								<div class="font16">票品分组</div>					
							    <div class="grouplineList" v-if="data.groupLine">
									<div class="fix" v-for="(item,index) in data.groupLine" :key="index">
										<el-tag  type="success" size="medium">{{index}}组</el-tag>
										      <div v-for="(iitem, iindex) in item" class="ML20">
												<span v-for="(titem, tindex) in data.ticketPrice" v-if="titem.key == iitem">{{titem.value}}</span>
												<span v-for="(titem, tindex) in data.presentTicketPrice" v-if="titem.key == iitem">{{titem.value}}</span>
										    </div>
									</div>						 
								</div>								
							</div>
							<div class="MT50">
								<div class="font16">出号方式</div>				
								<el-radio-group v-model="data.numberType" disabled>
									<div class="MT20"><el-radio :label="0">按系统出票时间从前往后排</el-radio></div>
									<div class="MT20"><el-radio :label="1">按系统出票时间从后往前排</el-radio></div>
									<div class="MT20"><el-radio :label="2">随机排序，跟系统出票时间无关</el-radio></div>
								</el-radio-group>	
								<small class="color-danger">
									<p class="MT10">出票时间：支付平台确认用户支付成功后，通知秀动系统正式出票的时间。</p>
									<p>注意：出票时间不等于支付平台（例如支付宝/微信）显示的支付时间。</p>
								</small>
							</div>                			
							<div class="MT50">                    
								<div class="font16">排队序号展示时间</div>				
								<el-radio-group v-model="data.buildTime" disabled>
									<div class="MT20"><el-radio :label="0">演出开始前1天展示</el-radio></div>
									<div class="MT20"><el-radio :label="1">演出开始当天0点展示</el-radio></div>
									<div class="MT20"><el-radio :label="2">演出开始前6个小时展示</el-radio></div>
									<div class="MT20"><el-radio :label="3">创建后立即生成展示</el-radio></div>
								</el-radio-group>
							</div>
							<div class="MT50">
								<div class="font16">赠票参与排号 <small class="color-danger">(开启后赠票会以赠票时间参与排号)</small></div>				
								<el-radio-group v-model="data.buildPresent" disabled>
									<div class="MT20"><el-radio :label="1">开启</el-radio></div>
									<div class="MT20"><el-radio :label="0">关闭</el-radio></div>
								</el-radio-group>
							</div>
						</template>
					</el-tab-pane>
					<el-tab-pane name="3" :disabled="data.type != 4 ">
						<span slot="label">自定义上传</span>
						<template  v-if="tabValue == '3'">
							<div class="temp-head clearfix">
									<div class="font16 MB20">1、请先下载售票排队号导入表，在表格中填写每张售票对应的排队号</div>
									<el-button type="primary" @click="download(1)" size="small" :loading="downLoading"  :disabled="data.type != 4 ">下载排队号导入表</el-button>									
							</div>                            
							<div class="temp-head clearfix MT50">
								<div class="font16 MB20">2、填写好售票座位号导入表后，在下方上传。上传成功后会在用户票夹中显示座位号。</div>
									<div>
										<el-upload
											action
											:http-request="uploadExcel"
											:auto-upload="false"
											ref="upload"
											accept=".xls, .xlsx"
											:show-file-list="true"
											:before-upload="beforeUpload"
											:file-list="fileList"
											:on-change="onChange"
											:on-remove="onRemove"
											:disabled="uploadLoading"
											style="width:100%">
											<el-button size="small" style="width:100%" type="primary" :loading="uploadLoading"  :disabled="data.type != 4 ">{{uploadLoading ? '上传中' : '上传排队号导入表'}}</el-button>
										</el-upload>
									</div>
									
									<div class="download color-theme MT20" v-if="siteFileUrl && data.siteFileUrl" style="cursor: pointer;"  @click="downloadExcel(data.siteFileUrl)" >{{sessionName}}排队号导入表.xlsx</div>
																	
							</div>				
							<div class="MT50">                    
								<div class="font16">请选择排队号号展示时间</div>				
								<el-radio-group v-model="data.buildTime" disabled>
									<div class="MT20"><el-radio :label="0">演出开始前1天展示</el-radio></div>
									<div class="MT20"><el-radio :label="1">演出开始当天0点展示</el-radio></div>
									<div class="MT20"><el-radio :label="2">演出开始前6个小时展示</el-radio></div>
									<div class="MT20"><el-radio :label="3">创建后立即生成展示</el-radio></div>
								</el-radio-group>
							</div>
								
						</template>
					</el-tab-pane>
				</el-tabs>	
			</div>
            <div class="drawer-footer">
                <el-button @click="close">关闭</el-button>
				<el-button type="primary" plain icon="el-icon-refresh" @click="resetNum()">重置序号</el-button>
                <el-button type="primary" @click="onSubmit" :loading="btnLoading" :disabled="data.type != 4 || canSubmit"
                    >确认</el-button>
            </div>
        </el-drawer>
    </div>
</template>

<script>
import {downloadFileTmp,uploadFileTmp} from '@/assets/js/commonApi.js';
export default {
   
    components:{},
    data(){
        return{
            drawerShow:false,
            btnLoading:false,
            downLoading:false,
            uploadLoading:false,
            canSubmit:false,
			fileList:[],
			type:1,
			numberType:0,
			buildTime:'',
			sessionName:'',
			data:{},
			groupLine:[],
			ticketPrice:[],
			
			grouplineName:[],
			num:1,
			
			temp: {
				params: [{key:''}]
			},
			ParamsNum: 0,	
			tabValue:'',
			siteFileUrl:false,
			buildPresent:1,
			
			
        }
    },
    methods:{
        
		show(data) {			
			this.data = data
			console.log(this.data )
		    this.id = data.sessionId;
		    this.sessionName = data.sessionName
		    this.drawerShow = true;
			this.ticketPrice = data.ticketPrice
			
			if( this.data.type == 4 ){
				this.tabValue = '3'
			}else if( this.data.type == 5  ){
				this.tabValue = '1'
			}else{
				this.tabValue = '2'
			}
			if( this.data.siteFileUrl ){
				this.siteFileUrl = true
			}
		},
        close(){
            this.btnLoading = false
            this.drawerShow = false;
			this.canSubmit = false
			this.fileList = [];
			
        },
		
        async downloadExcel(siteFileUrl){
            const _url = siteFileUrl;		    
        	await downloadFileTmp(_url, this.sessionName + '排队号导入表.' + this.$utils.getFileType(_url));		   
        },		
		resetNum(){
			this.$confirm('重置序号后所有已生成的排队序号将会删除，请在确认无误后重置!', '重置序号', {
				confirmButtonText: '确认重置',
				cancelButtonText:'取消',
				showClose:false,
				customClass:'mpop',
				closeOnClickModal: false,
				closeOnPressEscape: false
			}).then(() => {
			    this.$request({
			        url: '/activity/queue/number/reset',
			        params: {sessionId:this.id },
			        method: "post",
			    })
			        .then((res) => {
			            const { state, msg } = res.data;
			            if (state == 1) {	
							this.$message.success("重置成功！");
							window.location.reload()
			            } else {
			                this.$message.error(msg || "操作失败,请重试");
			            }
			        })
			        .catch(() => {
			            this.$message.error("操作失败,请重试");
			        })
			}).catch(() => { })
		},			 
		//下载模板
		download(type){
			let filePath;
			if( type == 1 ){
				this.downLoading = true;
			}			
			this.$request({
				url: '/activity/queue/number/template',
				method: 'POST',
				params: {sessionId:this.id } ,
			}).then(res => {
				const { state, msg, result } = res.data;
				if( state == 1 && result ){
					filePath = result.url;					
					downloadFileTmp(filePath,this.sessionName + `排队号导入表.`+this.$utils.getFileType(filePath));
					if( type == 1 ){
						this.downLoading = false;
					}					
				}else{
					if( type == 1 ){
						this.downLoading = false;
					}					
					return this.$message.error(msg || '下载失败');
				}
			}).catch(() => {
				this.$message.error('请求错误');
			}).finally(() => {
				
				if( type == 1 ){
					this.downLoading = false;
				}
			});
		},
		async uploadExcel(req){
			
		    this.btnLoading = true;
		    this.uploadLoading = true;
		    const formData = new FormData();
		    formData.append('file', req.file);
		
		    let filePath;
			let fileFullPath;
		    const res = await uploadFileTmp(formData).catch(err => {
		        this.$message({message: err.message, type: 'error'});
		    }).finally(() => {
		        this.uploadLoading = false;
		    });
		    const{state,msg,result} = res.data;
		    if (state == 1 && result) {
				
		        filePath = result.url;
				fileFullPath = result.urlFull
				
				this.filePath = result.url
				this.fileFullPath = result.urlFull
		    }else{
		        this.$message.error(msg || '上传失败');
		    }
			
			this.type = this.tabValue ==='2' ? 4 : this.type
		    this.$request({
		        url:'/activity/queue/number/setting',
		        method: 'post',
		        data:{
		    		buildTime:this.buildTime,
		    		numberType:this.numberType,
		    		sessionId: this.id,
		    		type:this.type,
		    		groupLine:{},
		    		buildPresent:this.buildPresent,
		    		filePath:filePath,
		    	}
		    })
		        .then(res => {
		            const{state,msg,result} = res.data;
		            if (state == 1) {
		                this.$message.success('操作成功');
		                window.location.reload()
		                
		            }else{
		                this.$message.error(msg || '操作失败');
		                this.canSubmit = false;
						
		            }
		        })
		       
		},
		beforeUpload(file){
		    const type = this.$utils.getFileType(file.name)
		    const isType = type === 'xls' || type === 'xlsx';
		    if (!isType) {
		        this.$message.error('仅支持xls、xlsx文件格式,请重新选择上传文件!');
		    }
		    return isType;
		},
		onSuccess(){
		    this.$emit('onUpdate');
		    this.close();
		},
		onChange(file){
		    if( file ){
				this.siteFileUrl = false				
				this.MsgText = ''
		        this.fileList = [{...file}]
		        this.canSubmit = false;
		    }else{
		        this.canSubmit = true;
		    }
		},
		onRemove(){
		    this.canSubmit = true;
		},
		onSubmit(){
			this.$refs.upload.submit(); 
		},
       
    }
}
</script>

<style lang="scss" scoped>
.temp-head{
    line-height: 28px;
}
.temp-table{
    margin-top: 15px;
}
.temp-tips{
    border-bottom: 1px #EBEEF5 solid;
    padding-bottom: 15px;
    .el-icon-info{
        font-size: 16px;
    }
    .color-regular{
        margin-top: 8px;
        font-size: 13px;
        line-height: 20px;
    }
}
.font16{
	font-size:16px;
	color:#000
}
.MT50{
	margin-top: 50px;
}
.MB20{
	margin-bottom: 20px;
}
.W70{
	width: 70%;
	margin-left: 20px;
}
.grouplineList{
    margin-top: 20px;
	.fix{
		display: flex;
		justify-content: flex-start;
		align-items: center;
		margin-top: 20px;
		.groupBox{
			margin-left: 30px;
		}
	}
}
</style>